import Resource from '../../resource';
import request from '../../../utils/request';

class Transfer extends Resource {
    constructor() {
        super('transfer');
    }

    SendRequest(query) {
        return request({
            url: `/transfer/internal/request`,
            method: 'post',
            data: query,
        })
    }

    listTransferRequests(){
        return request({
            url: `/transfer/list`,
            method: 'get',
        })
    }

    transferLookups(query){
        return request({
            url: `/transfer/lookups/get`,
            method: 'post',
            data: query
        })
    }

    deleteTransfer(id){
        return request({
            url: `/transfer/${id}/delete`,
            method: 'post'
        })
    }

}

export {Transfer as default};
