import Resource from '../../../api/resource'
import request from '../../../utils/request'

class Student extends Resource {
  constructor() {
    super('students')
  }

  home(id) {
    return request({
      url: `/students/dashboard`,
      method: 'get',
    })
  }

  getEdit(payload) {
    return request({
      url: `/students/Edit/${payload}`,
      method: 'get',
    })
  }

  lookup(payload) {
    return request({
      url: `/lookups/get`,
      method: 'post',
      data: payload
    })
  }

  export(payload) {
    return request({
      url: `/students/export`,
      method: 'post',
      responseType: 'blob',
      data: payload
    })
  }

  search(payload) {
    return request({
      url: `/students/search`,
      method: 'post',
      data: payload,
    })
  }

  studentCourses() {
    return request({
      url: `/students/courses`,
      method: 'post'
    })
  }

  show_my_course(courseId) {
    return request({
      url: `/students/courses/${courseId}`,
      method: 'get',
    })
  }

  takenCourses() {
    return request({
      url: `/students/academic/taken-courses`,
      method: 'get',
    })
  }

}

export { Student as default }
