import Student from '../../../../api/student/students/student';
import fileDownload from 'js-file-download'

const student = new Student();

const studentModule = {
  namespaced: true,
  state: {
    item: {},
    dashboard: null,
    items: [],
    total: 0,
    lookups: null,
    load: false,
    courses:[],
    student:null,
    takenCourses:[]
  },
  getters: {
    items(state) {
      return state.items;
    },
    item(state) {
      return state.item;
    },
    load(state) {
      return state.load;
    },
    total(state) {
      return state.total;
    },
    status(state) {
      return state.item.removed;
    },
    dashboard(state) {
      return state.dashboard;
    },
    lookups(state) {
      return state.lookups;
    },
    courses(state) {
      return state.courses;
    },
    student(state) {
      return state.student
    },
    takenCourses(state) {
      return state.takenCourses;
    }
  },
  mutations: {
    SET_ITEMS: (state, users) => {
      state.items = users;
    },
    SET_TOTAL_ITEMS: (state, total) => {
      state.total = total;
    },
    SET_ITEMS_LOAD: (state, load) => {
      state.load = load;
    },
    SET_ITEM: (state, item) => {
      // let courses = { courses: item.courses };
      // let terms_grades = { terms_grades: item.terms_grades };
      // let programs_levels_grades = { programs_levels_grades: item.programs_levels_grades };
      // if (item.courses) {
      //   Object.assign(state.item, courses);
      // }
      // else if (item.terms_grades) {
      //   Object.assign(state.item, terms_grades);
      // }
      // else if (item.programs_levels_grades) {
      //   Object.assign(state.item, programs_levels_grades);
      // }
      // else {
      //   state.item = item;
      // }
      state.item = item
    },
    SET_LOOKUPS(state, lookups) {
      state.lookups = lookups;
    },
    SET_DASHBOARD: (state, dashboard) => {
      state.dashboard = dashboard
    },
    SET_COURSES(state,courses){
      state.courses = courses;
    },
    SET_STUDENT(state,student){
      state.student = student
    },
    SET_TAKEN_COURSES(state,takenCourses){
      state.takenCourses = takenCourses;
    }
  },
  actions: {
    dashboard({commit}, payload) {
      commit('app/UPDATE_LOAD', true, {root: true});
      return new Promise((resolve, reject) => {
        student.home(payload.id)
          .then(response => {
            commit('app/UPDATE_LOAD', false, {root: true});
            commit('SET_DASHBOARD', response.data);
            resolve();
          })
          .catch(error => {
            commit('app/UPDATE_LOAD', false, {root: true});
            reject(error);
          });
      });
    },
    students({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        student.list(payload.query).then(response => {
          commit('SET_ITEMS', response.data);
          commit('SET_TOTAL_ITEMS', response.meta.count);
          commit('SET_ITEMS_LOAD', false);
          commit('SET_LOOKUPS', response.meta.lookup);
          resolve();
        });
      });
    },
    put({ commit }, payload) {
      commit('app/UPDATE_LOAD', true, { root: true });
      return new Promise((resolve, reject) => {
        student
          .put(payload.id, payload.query)
          .then(response => {
            commit('app/UPDATE_LOAD', false, { root: true });
            resolve();
          })
          .catch(error => {
            commit('app/UPDATE_LOAD', false, { root: true });
            reject(error);
          });
      });
    },
    get({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        student
          .get(payload)
          .then(response => {
            commit('SET_ITEM', response.data);
            commit('SET_STUDENT', response.data.student);
            commit("SET_LOOKUPS",response.meta);
            commit('app/UPDATE_PAGE_DETAILS', response.data.student, { root: true });
            commit('SET_ITEMS_LOAD', false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getEdit({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        student
          .getEdit(payload)
          .then(response => {
            commit('SET_ITEM', response.data);
            commit("SET_LOOKUPS",response.meta);
            commit('app/UPDATE_PAGE_DETAILS', response.data.student.name, { root: true });
            commit('SET_ITEMS_LOAD', false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    remove({ commit }, id) {
      return new Promise((resolve, reject) => {
        student.remove(id).then(response => {
          resolve();
        });
      });
    },
    restore({ commit }, id) {
      return new Promise((resolve, reject) => {
        student.restore(id).then(response => {
          resolve();
        });
      });
    },
    lookup({commit},payload){
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        student
          .lookup(payload)
          .then(response => {
            commit("SET_LOOKUPS",response.success);
            commit('SET_ITEMS_LOAD', false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    export({commit},payload){
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        student.export(payload)
          .then(response => {
            fileDownload(response,'Students.xlsx');
            commit('SET_ITEMS_LOAD', false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    search({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        student.search(payload.query).then(response => {
          commit('SET_ITEMS', response.data);
          commit('SET_ITEMS_LOAD', false);
          resolve(response);
        });
      });
    },
    getCourses({commit}){
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        student.studentCourses().then(response => {
          commit('SET_COURSES', response.data);
          commit('SET_TOTAL_ITEMS', response.meta && response.meta.count);
          commit('SET_LOOKUPS', response.meta && response.meta.lookup);
          commit('SET_ITEMS_LOAD', false);
          resolve(response.data);
        });
      });
    },
    getCourseDetails({ commit }, payload) {
      return new Promise((resolve, reject) => {
        student.show_my_course(payload.course_id).then(response => {
          commit('SET_ITEM', response.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getTakenCourses({commit}){
      return new Promise((resolve, reject) => {
        student.takenCourses().then(response => {
          commit('SET_TAKEN_COURSES', response.data.taken_courses);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    }
  },


};

export default studentModule;
